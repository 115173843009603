/* Loaders */

@import "variables";
@import "colored-balls";
@import "glowing-ball";
@import "pixel-loader";
@import "square-box";
@import "circle-loader";
@import "jumping-dots-loader";
@import "bar-loader";
@import "square-path-loader";
@import "dot-opacity-loader";
@import "flip-square-loader";
@import "moving-square-loader";