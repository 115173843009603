/* Toast */

.jq-toast-wrap {
    .jq-icon-success {
        background-color: theme-color("success");
    }
    .jq-icon-info {
        background-color: theme-color("info");
    }
    .jq-icon-warning {
        background-color: theme-color("warning");
    }
    .jq-icon-error {
        background-color: theme-color("danger");
    }
}