/* Icheck */

.icheck,
.icheck-flat,
.icheck-square,
.icheck-line {
  margin-bottom: .5rem;
  label {
    font-size: .8125rem;
    line-height: 1.5;
    margin-left: 5px;
    margin-bottom: 0;
  }
}
