/* Accordions */

.accordion {
  .card {
    margin-bottom: 0.625rem;
    border-radius: .25rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    .card-header {
      background-color: transparent;
      border: none;
      padding: 0;
      font-size: 14px;
      * {
        font-weight: $font-weight-medium;
        font-size: 1rem;        
      }
      a {
        display: block;
        text-decoration: none;
        font-size: 0.9375rem;
        position: relative;
        padding: .75rem 1.70rem .75rem 1.25rem;
        color: #ffffff;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        transition-property: border-color;
        -webkit-transition-property: border-color;
        @include transition(color .5s ease);
        padding-right: .5rem;
        &:before {
          font-family:"Material Design Icons";
          position: absolute;
          right: 7px;
          top: 10px;
          font-size: 18px;
          display: block;
          color: rgba(255, 255, 255, 0.7);
        }
        &[aria-expanded="true"] {
          color: theme-color(primary);
          &:before {
            content: "\F140";
          }
        }
        &[aria-expanded="false"] {
          &:before{
            content: "\F143";
          }
        }
      }
    }
    .card-body {
      font-size: 14px;
      padding: 0 2rem 2rem 2rem;
      font-weight: normal;
      line-height: 1.5;
      i {
        font-size: 1.25rem;
      }
    }
  }
  &.accordion-bordered {
    .card {
      margin: 0 2rem;
      border-top: 1px solid $border-color;
      box-shadow: none;
      border-radius: 0;
      .card-header,
      .card-body {
        padding-left: 0;
        padding-right: 0;
      }
      .card-header {
        a {
          &:before {
            color: theme-color(danger);
          }
          &[aria-expanded="true"] {
            color: inherit;
            &:before {
              content: "\F062";
            }
          }
          &[aria-expanded="false"] {
            &:before {
              content: "\F04A";
            }
          }
        }
      }
      &:first-child {
        border-top: 0;
      }
    }
  }
  &.accordion-filled {
    .card {
      padding: 0;
      .card-header {
        padding: 0;        
        a {
          padding: 2rem 4.5rem 2rem 2rem;
          @include transition(all .2s linear);
          &:before {
            top: 40%;
            right: 40px;
          }
          &[aria-expanded="true"] {
            background: theme-color(info);
            color: $white;
            &:before {
              content: "\F143";
              color: $white;
            }
          }
          &[aria-expanded="false"] {
            &:before {
              content: "\F140";
            }
          }
        }
      }
      .card-body {
        padding: 0 2rem 2rem 2rem;
        background: theme-color(info);
        color: $white;
      }
    }
  }
  &.accordion-solid-header {
    .card {
      padding: 0;
      .card-header {
        padding: 0;        
        a {
          padding: 2rem 4.5rem 2rem 2rem;
          @include transition(all .2s linear);
          &:before {
            top: 40%;
            right: 40px;
          }
          &[aria-expanded="true"] {
            background: theme-color(primary);
            color: $white;
            &:before {
              content: "\F143";
              color: $white;
            }
          }
          &[aria-expanded="false"] {
            &:before {
              content: "\F140";
            }
          }
        }
      }
      .card-body {
        padding: 2rem;
      }
    }
  }
  &.accordion-solid-content {
    .card {
      padding: 0;
      .card-header {
        padding: 0;        
        a {
          padding: 2rem 4.5rem 2rem 2rem;
          @include transition(all .2s linear);
          &:before {
            top: 40%;
            right: 40px;
          }
          &[aria-expanded="true"] {
            color: inherit;
            &:before {
              content: "\F143";
            }
          }
          &[aria-expanded="false"] {
            &:before {
              content: "\F140";
            }
          }
        }
      }
      .card-body {
        padding: 2rem;
        background: theme-color(success);
        color: $white;
      }
    }
  }
  &.accordion-multi-colored {
    .card {
      .card-header,
      .card-body {
        background: transparent;
        color: $white;
      }
      &:nth-child(1) {
        background: theme-color(primary);
      }
      &:nth-child(2) {
        background: theme-color(success);
      }
      &:nth-child(3) {
        background: theme-color(danger);
      }
      &:nth-child(4) {
        background: theme-color(warning);
      }
      &:nth-child(5) {
        background: theme-color(info);
      }
      .card-header {
        a {
          &[aria-expanded="true"] {
            color: inherit;
            &:before {
              content: "\F143";
            }
          }
          &[aria-expanded="false"] {
            &:before {
              content: "\F140";
            }
          }
        }
      }
    }
  }
}
